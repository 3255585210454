import { render, staticRenderFns } from "./index.vue?vue&type=template&id=502838e0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=502838e0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('C:/agent/_work/262/s/salonhomepage/client/components/Banner.vue').default,LandingPageNews: require('C:/agent/_work/262/s/salonhomepage/client/components/LandingPageNews.vue').default,Giftcard: require('C:/agent/_work/262/s/salonhomepage/client/components/Giftcard.vue').default})
