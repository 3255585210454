
import Banner from "~/components/Banner.vue";
import Giftcard from "~/components/Giftcard.vue";
import LandingPageNews from "~/components/LandingPageNews.vue";
import SlideGroup from "~/components/slideCards.vue";
import { TheSalonSection } from "@fixit/fixit-salons-info";
import { TheInstagramFeed } from "@fixit/instagram-images";
import FIcon from "@fixit/icons/src/components/FIcon.vue";
import CmsHomepageModuleEnum from "~/static/enums/modules/CmsHomepageModuleEnum";
import { waitFor } from "vue-wait";
import { mapWaitingActions } from "vue-wait";

export default {
  head() {
    return {
      title: `${this.$store.getters["cmsConfigHomepage/getChainName"]}`,
      titleTemplate: `%s |  ${this.$store.getters["cmsConfigHomepage/getHomepageSiteUrl"]}`,
      meta: [
        {
          hid: "og:titlw",
          name: "og:titlw",
          content: `${this.$store.getters["cmsConfigHomepage/getChainName"]} |  ${this.$store.getters["cmsConfigHomepage/getChainName"]}`,
        },
        {
          hid: "og:description",
          name: "og:description",
          content:
            this.$store.getters["cmsConfigHomepage/getCmsConfigHomepage"]
              ?.homepageDescription,
        },
        {
          hid: "og:url",
          name: "og:url",
          content:
            this.$store.getters["cmsConfigHomepage/getCmsConfigHomepage"]
              ?.siteUrl,
        },
        {
          hid: "og:image",
          name: "og:image",
          content: this.$store.getters["cmsConfigHomepage/getLogo"],
        },
      ],
    };
  },
  components: {
    Banner,
    Giftcard,
    LandingPageNews,
    SlideGroup,
    TheSalonSection,
    TheInstagramFeed,
    FIcon,
  },
  layout: "HomepageLayout",
  name: "Home",
  data: () => ({
    isLandingPage: true,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
    isMounted: false,
  }),
  computed: {
    homepageModules() {
      var modules = this.$store.getters["cmsConfigHomepage/getModules"];
      let showPrices = modules.find(
        (m) => m.moduleId == CmsHomepageModuleEnum.PRICES
      )?.visible;
      let prices = true;
      if (showPrices != undefined && showPrices != null) prices = showPrices;
      return {
        treatments: modules.find(
          (m) => m.moduleId == CmsHomepageModuleEnum.HOMEPAGETREATMENTS
        )?.visible,
        giftcards:
          this.$store.getters["cmsConfigHomepage/getGiftcardActiveStatus"],
        news:
          modules.find(
            (m) => m.moduleId == CmsHomepageModuleEnum.HOMEPAGEARTICLES
          )?.visible && this.$store.getters["cmsConfigHomepage/newsVisible"],
        departments: modules.find(
          (m) => m.moduleId == CmsHomepageModuleEnum.CHAINDEPARTMENTS
        )?.visible,
        instagram: modules.find(
          (m) => m.moduleId == CmsHomepageModuleEnum.IMAGESINSTAGRAM
        )?.visible,
        products:
          modules.find(
            (m) => m.moduleId == CmsHomepageModuleEnum.SUPPLIERBRANDS
          )?.visible &&
          this.$store.getters["cmsConfigHomepage/getWebshopActiveStatus"],
        prices: prices,
      };
    },
    popularTreatment() {
      return this.$store.getters["treatments/getPopularTreatments"]
        .slice(0, 4)
        .map((x) => ({
          imgUrl:
            x.ftcCategoryImgUrlsThumbnails &&
            x.ftcCategoryImgUrlsThumbnails.length
              ? x.ftcCategoryImgUrlsThumbnails[
                  Math.floor(
                    Math.random() * x.ftcCategoryImgUrlsThumbnails.length
                  )
                ]
              : "https://res.cloudinary.com/odin-systemer-test/image/upload/v1665556556/defaults/default-treatment.svg",
          title: x.treatmentName,
          middleText: x.durationMinutes + " min",
          currencyText: "Fra",
          price: x.price + ",-",
          btnText: "Les mer",
          btnLink: x.urlSafeTreatmentName + "/" + x.urlSafe,
        }));
    },
    popularProduct() {
      return this.$store.getters['popularProducts/getProducts'].slice(0,4).map(x => ({
        imgUrl: x.imageUrl,
        title: x.productName,
        middleText: x.brand + " " + x.productSerie,
        currencyText: "",
        price: x.normalPrice + ',-',
        btnLink: `${this.$config.PORTAL_URL}/nettbutikk/${x.chainId}/produkt/${x.urlSafeName}/${x.idBase64.base64Guid}`
        }));
    },
    salons() {
      return this.$store.getters["salon/getSalons"];
    },
    news() {
      let news = this.$store.getters["news/getNews"];
      return news ? news : [];
    },
    instagramImages() {
      return this.$store.getters["instagramImages/getInstagramImages"];
    },
    chainId() {
      return this.$store.getters["cmsConfigHomepage/getChainId"];
    },
    isDraft() {
      return this.$store.getters["cmsConfigHomepage/isDraftMode"];
    },
  },
  async mounted() {
    this.isMounted = true;
    if (this.homepageModules.treatments) {
      waitFor(`loading treatments`, async function () {
        await this.$store.dispatch("treatments/fetchPopularTreatments", null);
      }).apply(this);
    }
    if (this.homepageModules.products) {
      waitFor(`loading popular products`, async function () {
        await this.$store.dispatch("popularProducts/fetchPopularProducts");
      }).apply(this);
    }
    if (this.homepageModules.instagram)
      await this.$store.dispatch("instagramImages/fetchLastestInstagramImages");
  },
  async created() {
    if (!this.news.length) {
      this.fetchNewsBySiteIds();
    }
  },
  methods: {
    ...mapWaitingActions("news", {
      fetchNewsBySiteIds: "loading initailize homepage fetchNewsBySiteIds",
    }),
  },
  beforeDestroy() {
    this.$store.dispatch("treatments/resetPopularTreatmentsState");
  },
};
