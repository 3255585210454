
import FIcon from "@fixit/icons/src/components/FIcon.vue";

export default {
  components: { FIcon },

  props: {
    items: {
      type: Array,
      default: null,
    },
    newTabOnClick: {
      type: Boolean,
      default: false,
    },
    draftMode: {
      type: Boolean,
      default: false,
    },
    showPrice: {
      type: Boolean,
    },
  },
  data: () => ({
    isMounted: false,
  }),
  mounted() {
    this.isMounted = true;
  },
  methods: {
    onClick(path) {
      if (this.newTabOnClick) {
        window.open(path, "_blank");
      } else {
        this.$router.push("/Behandlinger/" + path);
      }
    },
    getImg(imgUrl) {
      //Default image if the treatment/product doesnt have a set image url
      return imgUrl
        ? imgUrl
        : "https://res.cloudinary.com/odin-systemer-test/image/upload/v1665556556/defaults/default-treatment.svg";
    },
  },
};
