
import FIcon from '@fixit/icons/src/components/FIcon.vue'

export default {
    components: { FIcon },

    data: () => ({
        isMounted: false,
    }),
    computed: {
        useConstrastColors() {
            return this.$store.getters['cmsConfigHomepage/primaryAndSecondaryAreEqual']
        }
    },

    mounted() {
        this.isMounted = true;
    }
}
